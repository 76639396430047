// import * as bootstrap from 'bootstrap';

import '@theme/assets/styles/legacy.scss';
import '@theme/assets/styles/main.scss';

import { production, home } from '@theme/assets/scripts/utils.js';

import anime from 'animejs';

/*
 * Initialise Scripts
 */
function init() {
    window.addEventListener('load', function () {
        initBackground();

        // Homepage Only
        if (home()) {
            initHero();
        }
    });
}

init();

/*
 * Background Effects
 */

function initBackground() {
    // Background Noise
    const noiseUpper = document.querySelector('.fx-noise-upper');
    const noiseLower = document.querySelector('.fx-noise-lower');

    setInterval(() => {
        noiseUpper.style.transform =
            'translateX(' +
            (Math.random() * 100 - 50) +
            'px) translateY(' +
            (Math.random() * 100 - 50) +
            'px)';

        noiseLower.style.transform =
            'translateX(' +
            (Math.random() * 100 - 50) +
            'px) translateY(' +
            (Math.random() * 100 - 50) +
            'px)';
    }, 80);
}

/*
 * Hero Effects
 */

function initHero() {
    const screen = window.innerWidth;

    const title = document.getElementById('jumbo-title');
    const pretext = document.getElementById('jumbo-pretext');
    const subtext = document.getElementById('jumbo-subtext');

    const terrain = document.querySelector('.scene > .terrain');
    const dino = document.querySelector('.scene > .dino');
    const bones = document.querySelector('.scene > .bones');
    const circuitLeft = document.querySelector('.scene > .backdrop > .circuit-left');
    const circuitRight = document.querySelector('.scene > .backdrop > .circuit-right');
    const smokeLeft = document.querySelector('.scene > .backdrop > .smoke-left');
    const smokeRight = document.querySelector('.scene > .backdrop > .smoke-right');

    if (screen < 768) {
        anime({
            targets: title,
            translateX: {
                value: [500, 0],
                duration: 2000,
                delay: 500
            },
            opacity: {
                value: [0, 1],
                duration: 2000,
                easing: 'easeOutSine',
                delay: 500
            }
        });

        anime({
            targets: pretext,
            translateX: {
                value: [-500, 0],
                duration: 3000,
                delay: 100
            },
            opacity: {
                value: [0, 1],
                duration: 3000,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: subtext,
            translateY: {
                value: [50, 0],
                duration: 1000,
                easing: 'easeOutSine',
                delay: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 1000,
                easing: 'easeOutSine',
                delay: 1000
            }
        });

        anime({
            targets: terrain,
            translateY: {
                value: [80, 50],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: dino,
            translateY: {
                value: [100, 5],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            },
            translateX: {
                value: [50, -50],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            },
            opacity: {
                value: [0, 1],
                duration: 2000,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: dino,
            opacity: [
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 50,
                    delay: 3000
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                }
            ],
            delay: 5000,
            loop: true
        });

        anime({
            targets: bones,
            opacity: [
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 200
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 150
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 3000
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 200
                }
            ],
            delay: 5000,
            loop: true
        });
    } else if (screen > 768 && screen < 992) {
        anime({
            targets: title,
            translateX: {
                value: [500, 0],
                duration: 2000,
                delay: 500
            },
            opacity: {
                value: [0, 1],
                duration: 2000,
                easing: 'easeOutSine',
                delay: 500
            }
        });

        anime({
            targets: pretext,
            translateX: {
                value: [-500, 0],
                duration: 3000,
                delay: 100
            },
            opacity: {
                value: [0, 1],
                duration: 3000,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: subtext,
            translateY: {
                value: [50, 0],
                duration: 1000,
                easing: 'easeOutSine',
                delay: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 1000,
                easing: 'easeOutSine',
                delay: 1000
            }
        });

        anime({
            targets: terrain,
            translateY: {
                value: [120, 70],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: dino,
            translateY: {
                value: [200, 65],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            },
            translateX: {
                value: [50, -50],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            },
            opacity: {
                value: [0, 1],
                duration: 2000,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: dino,
            opacity: [
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 50,
                    delay: 3000
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                }
            ],
            delay: 5000,
            loop: true
        });

        anime({
            targets: bones,
            opacity: [
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 200
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 150
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 3000
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 200
                }
            ],
            delay: 5000,
            loop: true
        });

        anime({
            targets: [circuitLeft, circuitRight],
            opacity: [
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                }
            ],
            delay: 1500
        });

        anime({
            targets: [circuitLeft, circuitRight],
            translateY: {
                value: [50, 0],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: [circuitLeft, circuitRight],
            opacity: [
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 2500
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 2000
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 200,
                    delay: 3100
                }
            ],
            loop: true
        });

        anime({
            targets: [smokeLeft, smokeRight],
            translateY: [
                {
                    value: [70, -10],
                    duration: 1500,
                    delay: 5500
                }
            ],
            opacity: [
                {
                    value: [0, 0.9],
                    duration: 1000,
                    delay: 5500
                },
                {
                    value: [0.9, 0],
                    duration: 1900,
                    delay: 700
                }
            ],
            loop: true
        });
    } else if (screen > 992 && screen < 1200) {
        anime({
            targets: title,
            translateX: {
                value: [500, 0],
                duration: 2000,
                delay: 500
            },
            opacity: {
                value: [0, 1],
                duration: 2000,
                easing: 'easeOutSine',
                delay: 500
            }
        });

        anime({
            targets: pretext,
            translateX: {
                value: [-500, 0],
                duration: 3000,
                delay: 100
            },
            opacity: {
                value: [0, 1],
                duration: 3000,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: subtext,
            translateY: {
                value: [50, 0],
                duration: 1000,
                easing: 'easeOutSine',
                delay: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 1000,
                easing: 'easeOutSine',
                delay: 1000
            }
        });

        anime({
            targets: terrain,
            translateY: {
                value: [120, 70],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: dino,
            translateY: {
                value: [200, 65],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            },
            translateX: {
                value: [50, -50],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            },
            opacity: {
                value: [0, 1],
                duration: 2000,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: dino,
            opacity: [
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 50,
                    delay: 3000
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                }
            ],
            delay: 5000,
            loop: true
        });

        anime({
            targets: bones,
            opacity: [
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 200
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 150
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 3000
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 200
                }
            ],
            delay: 5000,
            loop: true
        });

        anime({
            targets: [circuitLeft, circuitRight],
            opacity: [
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                }
            ],
            delay: 1500
        });

        anime({
            targets: [circuitLeft, circuitRight],
            translateY: {
                value: [50, 0],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: [circuitLeft, circuitRight],
            opacity: [
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 2500
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 2000
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 200,
                    delay: 3100
                }
            ],
            loop: true
        });

        anime({
            targets: [smokeLeft, smokeRight],
            translateY: [
                {
                    value: [70, -10],
                    duration: 1500,
                    delay: 5500
                }
            ],
            opacity: [
                {
                    value: [0, 0.9],
                    duration: 1000,
                    delay: 5500
                },
                {
                    value: [0.9, 0],
                    duration: 1900,
                    delay: 700
                }
            ],
            loop: true
        });
    } else {
        anime({
            targets: title,
            translateX: {
                value: [500, 0],
                duration: 2000,
                delay: 500
            },
            opacity: {
                value: [0, 1],
                duration: 2000,
                easing: 'easeOutSine',
                delay: 500
            }
        });

        anime({
            targets: pretext,
            translateX: {
                value: [-500, 0],
                duration: 3000,
                delay: 100
            },
            opacity: {
                value: [0, 1],
                duration: 3000,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: subtext,
            translateX: {
                value: [50, 0],
                duration: 1000,
                easing: 'easeOutSine',
                delay: 1000
            },
            opacity: {
                value: [0, 1],
                duration: 1000,
                easing: 'easeOutSine',
                delay: 1000
            }
        });

        anime({
            targets: terrain,
            translateY: {
                value: [200, 100],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: dino,
            translateY: {
                value: [150, 5],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            },
            translateX: {
                value: [50, -50],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            },
            opacity: {
                value: [0, 1],
                duration: 2000,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: dino,
            opacity: [
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 50,
                    delay: 3000
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 200
                }
            ],
            delay: 5000,
            loop: true
        });

        anime({
            targets: bones,
            opacity: [
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 200
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 150
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 3000
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 50
                },
                {
                    value: [1, 0],
                    duration: 200
                }
            ],
            delay: 5000,
            loop: true
        });

        anime({
            targets: [circuitLeft, circuitRight],
            translateY: {
                value: [50, 0],
                duration: 1500,
                easing: 'easeOutSine',
                delay: 100
            }
        });

        anime({
            targets: [circuitLeft, circuitRight],
            opacity: [
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 2500
                },
                {
                    value: [0, 1],
                    duration: 200
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [1, 0],
                    duration: 50,
                    delay: 2000
                },
                {
                    value: [1, 0],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 150
                },
                {
                    value: [1, 0],
                    duration: 50
                },
                {
                    value: [0, 1],
                    duration: 100
                },
                {
                    value: [0, 1],
                    duration: 200,
                    delay: 3100
                }
            ],
            loop: true
        });

        anime({
            targets: [smokeLeft, smokeRight],
            translateY: [
                {
                    value: [70, -10],
                    duration: 1500,
                    delay: 5500
                }
            ],
            opacity: [
                {
                    value: [0, 0.9],
                    duration: 1000,
                    delay: 5500
                },
                {
                    value: [0.9, 0],
                    duration: 1900,
                    delay: 700
                }
            ],
            loop: true
        });
    }
}
